import React from 'react'

const Home = () => {
  return (
    <div  className='flex justify-center items-center text-white text-3xl'>
       Home
    </div>
  )
}

export default Home