import React from 'react';
import './Spinner.css';

const Spinner = () => {
  return (
    <div>
        <div className="spinner">
            
        </div>
    </div>
  )
}

export default Spinner